import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useToast } from "@chakra-ui/react";
import { UseDisclosureReturn } from "@chakra-ui/react";
import React, { useState } from "react"
import { useForm } from "react-hook-form";

const FeedbackForm = ({control  } : {control :  UseDisclosureReturn }) => {
    
    const toast = useToast();
    const { handleSubmit, register, errors } = useForm()
    const onSubmit = values => {
        control.onClose();
        fetch(
            "https://prod-189.westeurope.logic.azure.com:443/workflows/7e04bc8c62b946558a90a2c57d04295d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dpC-VyrqRr3nKFA4-IJ2gXvcCpP-TfGdNHfsJlxsPbg",
            {
              method: "POST", // *GET, POST, PUT, DELETE, etc.x
              mode: "cors", // no-cors, *cors, same-origin
              cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
              credentials: "same-origin", // include, *same-origin, omit
              headers: {
                "Content-Type": "application/json",
      
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: "follow", // manual, *follow, error
              referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              body: JSON.stringify({
                ...values,
                url: document.location.toString(),
              }), // body data type must match "Content-Type" header
            }
          ).then(a => {
            toast({
              title: "Uw vraag is verstuurd.",
              description: " Wij streven er naar om binnen een dag te reageren.",
              status: "success",
              duration: 9000,
              isClosable: true,
            })
          })
    };
    return (
        <Modal isOpen={control.isOpen} onClose={control.onClose}>
        <ModalOverlay />
  
        <ModalContent style={{ marginTop: "10vh" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Feedback</ModalHeader>
       
            <ModalCloseButton />
            <ModalBody>
            <p style={{margin:"0 0 0.5rem 0" , fontSize: "80%"}}>Alle feedback, verzoeken en vragen zijn welkom. Wij reageren binnen 24 uur.</p>
              <FormControl isRequired >
             
                <FormLabel htmlFor="fname">
                  Mail adres
                </FormLabel>
                <Input
                  id="fname"
                  placeholder="administratie@jansenenjansen.nl"
                  type="email"
                  name="emailTo"
                  ref={register()}
                />
              </FormControl>
             
  
              <FormControl isRequired style={{ marginTop: "1.5rem" }}>
                <FormLabel htmlFor="message">Bericht:</FormLabel>
  
                <Textarea
                  placeholder=""
                  size="sm"
                  ref={register()}
                  name="message"
                  id="message"
                  defaultValue={""}
                />
              </FormControl>
            </ModalBody>
  
            <ModalFooter>
              <Button variant="ghost" onClick={control.onClose} mr={3}>
                Annuleer
              </Button>
              <Button variantColor="blue" type="submit">
                Verstuur e-mail
              </Button>
              <hr />
            </ModalFooter>
          
          </form>
        </ModalContent>
      </Modal>
    );

}

export default FeedbackForm;
import { useDisclosure } from "@chakra-ui/react"
import React, { useCallback } from "react"
import FeedbackForm from "./feedbackForm"

const FeedbackButton = () => {
  const control = useDisclosure()
  const onClick = useCallback(() => control.onOpen(), [control])
  return (
    <>
      {<FeedbackForm control={control} />}
      <div
        onClick={onClick}
        className="noPrint"
        style={{
          position: "fixed",
          backgroundColor: "#000",

          top: "46vh",
          color: "#fff",
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          padding: "0.4rem 0.3rem 0.4rem 0.1rem",
          fontFamily: "system-ui",
          fontSize: "75%",
          cursor: "pointer",
          userSelect: "none",
          right: "-0.3rem",
          borderRadius: "0.3rem",
          letterSpacing: "0,5px",
        }}
      >
        Feedback
      </div>
    </>
  )
}

export default FeedbackButton

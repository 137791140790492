/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

//import "tailwindcss/dist/base.css"
//import "tailwindcss/dist/"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import "typeface-lato"
import SEO from "../components/seo"
import FeedbackButton from "./../components/feedback/feedbackButton"
import { api } from "./../settings"
import Header from "./header"
import "./layout.css"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)


  const styles = {
    global: props => ({
      body: {
        bg: '#f5f5f5',
      },
    }),
  };

  const theme = extendTheme({
    styles
  })


  return (
    <ChakraProvider theme={theme} resetCSS>
      <SEO title="Kwitantie.app - Verstuur gratis Kwitanties. " />
   
      <Helmet 
        htmlAttributes={{
            lang: "nl-NL",
          }} 
        meta={[
        {
          name: `description`,
          content: `Creëer gratis een kwitantie. Download naar pdf of verstuur per e-mail. Geen wachtwoorden nodig.`,
        },
        {
          property: `og:title`,
          content: "Kwitantie.app - Verstuur gratis Kwitanties.",
        },
        {
          property: `og:description`,
          content: `Creëer gratis een kwitantie. Download naar pdf of verstuur per e-mail. Geen wachtwoorden nodig.`,
        },
        {
          property: `og:type`,
          content: `website`,
        }
      ]}>
        <link rel="preconnect" href={api} />
      </Helmet>

      <Header siteTitle="Gratis kwitanties versturen" className="noPrint" />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
        className="container"
      >
        <main>{children}</main>
      </div>
      <footer className="footer noPrint">
        <div>
          <a href="/">Home</a> | <a href="/privacy">privacy en disclaimer</a> |{" "}
          <a href="mailto:info@kwitantie.app">E-mail ons</a> |{" "}
          {new Date().getFullYear()}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <Link to="/factuur-voor-particulieren">
            facturen voor particulieren
          </Link>{" "}
          
          | <Link to="/belastingdienst">
            Belastingdienst
          </Link>{" "}
          | <Link to="/voorbeeld-kwitantie-pdf">voorbeeld kwitantie pdf</Link> |{" "}
          <a
            href="https://twitter.com/kwitantie"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
        </div>
      </footer>
      <FeedbackButton />
      </ChakraProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

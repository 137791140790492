import { useAuth0 } from "@auth0/auth0-react"
import {
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import featureFlag from "../utils/featureFlags"
import { AccountModal } from "./modal/accountModal"

const Header = ({ siteTitle }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const goToOverview = useCallback(() => {
    navigate("/overview")
  }, [])

  const goToNewKwitantie = useCallback(() => {
    navigate("/item/?id=new")
  }, [])

  const logoutCallback = useCallback(() => {
    logout({
      returnTo: "https://kwitantie.app/",
    })
    window.sessionStorage.removeItem("redirect")
  }, [])

  const settingsDisclosure = useDisclosure()

  return (
    <>
      <AccountModal settingsDisclosure={settingsDisclosure} />
      <header
        style={{
          background: `rebeccapurple`,
          marginBottom: `1.45rem`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: "21cm",
            padding: `0.45rem 0.0875rem`,
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: "180%",
              fontWeight: "bold",
              maxWidth: "21cm",
              display: "flex",
              flexDirection: "row",
              justifyContent: featureFlag("develop")
                ? "space-between"
                : "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Link
              to={isAuthenticated ? "/overview" : "/"}
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {isAuthenticated ? "Kwitantie.app" : siteTitle}
            </Link>
            {featureFlag("develop") && !isAuthenticated && (
              <span
                style={{
                  float: "right",
                  cursor: "pointer",
                  border: "1px solid #fff",
                  borderRadius: "5px",
                  color: "#fff",
                  fontSize: "50%",
                  padding: "0.3rem",
                }}
                onClick={loginWithRedirect}
                onKeyUp={loginWithRedirect}
                role="button"
                tabIndex={0}
              >
                Login
              </span>
            )}
            {featureFlag("develop") && isAuthenticated && (
              <Menu>
                <MenuButton
                  px={4}
                  py={2}
                  transition="all 0.2s"
                  rounded="md"
                  borderWidth="1px"
                  _hover={{ bg: "gray.100" }}
                  _expanded={{ bg: "gray.200" }}
                  _focus={{ outline: 0, boxShadow: "outline" }}
                  fontSize="16px"
                  color="#fff"
                  size=""
                >
                  <Icon name="chevron-down" />
                </MenuButton>
                <MenuList
                  style={{ zIndex: 9999999, fontSize: "14px", left: "-85px" }}
                >
                  <MenuItem onClick={goToOverview}>Overzicht</MenuItem>
                  <MenuItem onClick={goToNewKwitantie}>
                    Nieuwe kwitantie
                  </MenuItem>
                  <MenuItem onClick={settingsDisclosure.onToggle}>
                    Account gegevens
                  </MenuItem>
                  <MenuDivider />

                  <MenuItem onClick={logoutCallback}>Uitloggen</MenuItem>
                </MenuList>
              </Menu>
            )}
          </h1>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

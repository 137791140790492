import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import React from "react"

export const AccountModal = ({
  settingsDisclosure,
}: {
  settingsDisclosure: UseDisclosureReturn
}) => {
  return (
    <Drawer
      isOpen={settingsDisclosure.isOpen}
      placement="right"
      onClose={settingsDisclosure.onClose}
      //finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Maak een een account</DrawerHeader>

        <DrawerBody>
          <Input placeholder="Type here..." />
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={settingsDisclosure.onClose}>
            Annuleer
          </Button>
          <Button color="blue">Account verwijderen</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
